import { useAuth } from "../../utils/auth";
import { client } from "../../utils/axios";
import ms from "ms";
import { useQuery, useQueryClient } from "react-query";

const getTVActiveAccounts = (query, auth) => async () => {
  try {
    const { data } = await client.get(
      `${process.env.REACT_APP_TRADOVATE_URL}/getActiveAccounts/`,
      {
        headers: {
          "x-api-key": process.env.REACT_APP_TV_API_KEY,
        },
      }
    );
    // const test = data.slice(1, -1);
    // const pdfUrl = URL.createObjectURL(test);
    // return pdfUrl;
    return data;
  } catch (error) {
    // Re-throw error so it's caught by the useQuery's onError handler
    throw error;
  }
};

const useGetTVActiveAccounts = (query) => {
  const auth = useAuth();

  const {
    data,
    error,
    refetch,
    isLoading,
    isError, // This is a boolean that is true when an error occurs
  } = useQuery(["tvActiveAccounts", query], getTVActiveAccounts(query, auth), {
    staleTime: ms("20m"),
    refetchInterval: ms("20m"),
    refetchOnMount: false,
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    keepPreviousData: true,
    retry: false, // Retry can be set to false, or you can customize retries
    onError: (error) => {
      console.error("Error fetching data:", error);
    },
  });

  return {
    data: data,
    refetch,
    isLoading,
    error, // Return the error object
    isError, // Return a boolean indicating if there's an error
  };
};

export default useGetTVActiveAccounts;
