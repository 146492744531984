import { Box, Button, Flex, Heading, Grid, GridItem } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import AppCardLayout from "../../components/HomePageLayouts/appCardLayout";
import subscriptions from "../../assets/appLogos/Subscriptions.webp";
import activityLogs from "../../assets/appLogos/activityLogs.png";
import sendCredentials from "../../assets/appLogos/sendCredentials.avif";
import updateEmailImage from "../../assets/appLogos/updateEmail.png";

const FunctionalitySelection = () => {
  const navigate = useNavigate();
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      h="100vh"
      bg="gray.100">
      <Heading my={4}>ProjectX Functionalities</Heading>
      <Grid
        h="calc(100vh - 40px)"
        p={2}
        templateColumns={{
          base: "repeat(1, 1fr)",
          xl: "repeat(2, 1fr)",
          "3xl": "repeat(3,1fr)",
        }}
        templateRows="repeat(3, 1fr)"
        gap={4}
        overflow="scroll">
        <GridItem
          colSpan={1}
          borderRadius="15px"
          /* boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
        bgGradient={`linear(to-t, gray.200, gray.400)`} */
          color="#000"
          gap={4}
          /*         _hover={{ bgGradient: `linear(to-t, gray.100, gray.300)` }}
           */ display="flex"
          alignItems={`center`}
          justifyContent="center"
          onClick={() => navigate("/apps/projectX/sendCredentials/")}
          cursor="pointer">
          <AppCardLayout
            img={sendCredentials}
            description={"Send credentials to clients with ProjectX accounts "}
            Link={
              "https://docs.google.com/document/d/19EQk8F87XKaOqVmeFpzokJRkJj0iL2ML/edit#heading=h.mn3r46neaa0h"
            }
            appName={"Send Credentials"}
          />
        </GridItem>
        <GridItem
          colSpan={1}
          borderRadius="15px"
          /* boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
        bgGradient={`linear(to-t, gray.200, gray.400)`} */
          color="#000"
          gap={4}
          /*         _hover={{ bgGradient: `linear(to-t, gray.100, gray.300)` }}
           */ display="flex"
          alignItems={`center`}
          justifyContent="center"
          onClick={() => navigate("/apps/projectX/activityLog")}
          cursor="pointer">
          <AppCardLayout
            img={activityLogs}
            description={
              "View the activity log of all the clients with ProjectX accounts"
            }
            Link={
              "https://docs.google.com/document/d/19EQk8F87XKaOqVmeFpzokJRkJj0iL2ML/edit#heading=h.mn3r46neaa0h"
            }
            appName={"View Activity Log"}
          />
        </GridItem>
        <GridItem
          colSpan={1}
          borderRadius="15px"
          /* boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
        bgGradient={`linear(to-t, gray.200, gray.400)`} */
          color="#000"
          gap={4}
          /*         _hover={{ bgGradient: `linear(to-t, gray.100, gray.300)` }}
           */ display="flex"
          alignItems={`center`}
          justifyContent="center"
          onClick={() => navigate("/apps/projectX/UpdateEmail")}
          cursor="pointer">
          <AppCardLayout
            img={updateEmailImage}
            description={"Request to update ProjectX user Email address"}
            Link={
              "https://docs.google.com/document/d/19EQk8F87XKaOqVmeFpzokJRkJj0iL2ML/edit#heading=h.mn3r46neaa0h"
            }
            appName={"Update Email"}
          />
        </GridItem>
      </Grid>
    </Flex>
  );
};

export default FunctionalitySelection;
