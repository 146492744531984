import {
  Box,
  Flex,
  Heading,
  Input,
  Button,
  chakra,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  Tooltip,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Divider,
  Badge,
  Spinner,
  IconButton,
  Checkbox,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import {
  AddIcon,
  HamburgerIcon,
  ExternalLinkIcon,
  RepeatIcon,
  EditIcon,
  CheckIcon,
  AttachmentIcon,
} from "@chakra-ui/icons";

import React, { useState, useEffect } from "react";
import { AiOutlineEye, AiOutlineFilter } from "react-icons/ai";
import { BiDetail, BiEdit, BiFilter, BiPlus } from "react-icons/bi";
// import {
// } from "react-super-responsive-table";
import { colors } from "../../utils/colors";
import useAllFundedlists, {
  getTriggerCreation,
  getUpdateInvoice,
  setExportOptions,
  setKycReason,
  setMarkCreation,
  setMarkSigned,
} from "../../hooks/useQueries/useFunded";
import { useRecoilState, useRecoilValue } from "recoil";
import { filterFundedlistSelector, filterFundedlistState } from "../../recoil";
import {
  getInvoiceStatus,
  getPayedStatus,
  getSignInStatus,
  substringContent,
} from "../../utils/commen";
import "../../../node_modules/react-super-responsive-table/dist/index";
import Select from "react-select";
import DetailFunded from "./DetailFunded";
import Session from "supertokens-auth-react/recipe/session";
import Pagination1 from "../../utils/pagination";
import { arrayToCSV } from "../../utils/csv.util.js";

// const CustomTable = chakra(Table);
// const CustomThead = chakra(Thead);
// const CustomTbody = chakra(Tbody);
// const CustomTr = chakra(Tr);
// const CustomTh = chakra(Th);
// const CustomTd = chakra(Td);

const invoiceStatusOptions = [
  { value: "1", label: "PAID" },
  { value: "2", label: "ACTIVE" },
  //{ value: "2", label: "RECURRING ACTIVE" },
  { value: "3", label: "CANCELLED" },
  //{ value: "3", label: "RECURRING CANCELLED" },
  { value: "4", label: "FAILED" },
  //{ value: "4", label: "RECURRING FAILED" },
  { value: "5", label: "FINISHED" },
  //{ value: "5", label: "RECURRING FINISHED" },
];
const uAEOptions = [
  { value: "1", label: "Yes" },
  { value: "0", label: "No" },
];
const providerOptions = [
  { value: "CQG", label: "CQG" },
  { value: "Rithmic", label: "Rithmic" },
];

const paidOptions = [
  { value: "0", label: "Not Payed" },
  { value: "1", label: "Payed" },
];
const statusOptions = [
  { value: "success", label: "Success" },
  { value: "working", label: "Working" },
  { value: "not started", label: "Not started" },
  { value: "failed", label: "Failed" },
];
const accountTypeOptions = [
  { value: "FA", label: "FA" },
  { value: "FP", label: "FP" },
  { value: "FE", label: "FE" },
  { value: "FS", label: "FS" },
];
const signInOptions = [
  { value: "0", label: "Not Signin" },
  { value: "1", label: "Signin" },
];
const kycStatusOptions = [
  { value: "approved", label: "approved" },
  { value: "completed", label: "completed" },
  { value: "declined", label: "declined" },
  { value: "failed", label: "failed" },
  { value: "needs_review", label: "needs review" },
  { value: "not started", label: "not started" },
];
const CreationStatus = ({ fn }) => {
  if (fn?.creation_status === "success") {
    return (
      <Badge variant="outline" colorScheme="green">
        {fn?.creation_status}
      </Badge>
    );
  } else if (fn?.creation_status === "working") {
    return (
      <Badge variant="outline" colorScheme="orange">
        {fn?.creation_status}
      </Badge>
    );
  } else if (fn?.creation_status === null) {
    return <p>-</p>;
  } else {
    return (
      <Badge variant="outline" colorScheme="red">
        failed
      </Badge>
    );
  }
};

const FilterFunded = ({ filter, setfilter }) => {
  return (
    <div>
      <div
        style={{
          margin: "1.5rem 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <div style={{ width: "100%" }}>
          <p>Invoice Status:</p>
          <Select
            options={invoiceStatusOptions}
            isMulti
            //  defaultValue={filter.invoice_status?.map(e=>invoiceStatusOptions.find(s=>s.value==e))??[]}
            value={
              filter.invoice_status?.map((e) =>
                invoiceStatusOptions.find((s) => s.value == e)
              ) ?? []
            }
            onChange={(e) => {
              const val = e.valueOf();
              const arr = val.map((x) => x["value"]);
              //console.log(arr);
              //setInvoiceStatus(arr);
              setfilter({
                ...filter,
                invoice_status: arr,
              });
            }}
          />
        </div>

        <div style={{ width: "100%", margin: "0 0 0 1rem" }}>
          <p>KYC Status:</p>
          <Select
            options={kycStatusOptions}
            //  defaultValue={filter.kyc_status?.map(e=>kycStatusOptions.find(s=>s.value==e))??[]}
            value={
              filter.kyc_status?.map((e) =>
                kycStatusOptions.find((s) => s.value == e)
              ) ?? []
            }
            isMulti
            onChange={(e) => {
              const val = e.valueOf();
              const arr = val.map((x) => x["value"]);
              //setKycStatus(arr);
              setfilter({
                ...filter,
                kyc_status: arr,
              });
            }}
          />
        </div>
      </div>

      <div
        style={{
          margin: "1.5rem 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <div style={{ width: "100%" }}>
          <p>Paid:</p>
          <Select
            options={paidOptions}
            //  defaultValue={filter.payed? paidOptions.find(s=>s.value==filter.payed) : undefined}
            value={
              filter.payed
                ? paidOptions.find((s) => s.value == filter.payed)
                : undefined
            }
            onChange={(e) => {
              const val = e.valueOf();
              //setPaid(val["value"]);
              setfilter({
                ...filter,
                payed: val["value"],
              });
            }}
          />
        </div>
        <div style={{ width: "100%", margin: "0 0 0 1rem" }}>
          <p>Sign In:</p>
          <Select
            options={signInOptions}
            //  defaultValue={filter.is_signin? signInOptions.find(s=>s.value==filter.is_signin) : undefined}
            value={
              filter.is_signin
                ? signInOptions.find((s) => s.value == filter.is_signin)
                : undefined
            }
            onChange={(e) => {
              const val = e.valueOf();
              //setIsSignIn(val["value"]);
              setfilter({
                ...filter,
                is_signin: val["value"],
              });
            }}
          />
        </div>
      </div>

      <div
        style={{
          margin: "1.5rem 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <div style={{ width: "100%" }}>
          <p>Account Type:</p>
          <Select
            options={accountTypeOptions}
            isMulti={true}
            //  defaultValue={filter.accountType?.map(e=>accountTypeOptions.find(s=>s.value==e))??[]}
            value={
              filter.accountType?.map((e) =>
                accountTypeOptions.find((s) => s.value == e)
              ) ?? []
            }
            onChange={(e) => {
              const val = e.valueOf();
              const arr = val.map((x) => x["value"]);
              //setAccountType(arr);
              setfilter({
                ...filter,
                accountType: arr,
              });
            }}
          />
        </div>
        <div style={{ width: "100%", margin: "0 0 0 1rem" }}>
          <p>Status:</p>
          <Select
            options={statusOptions}
            isMulti
            //  defaultValue={filter.creation_status?.map(e=>statusOptions.find(s=>s.value==e))??[]}
            value={
              filter.creation_status?.map((e) =>
                statusOptions.find((s) => s.value == e)
              ) ?? []
            }
            onChange={(e) => {
              const val = e.valueOf();

              const arr = val.map((x) => x["value"]);
              //setStatus(arr);
              setfilter({
                ...filter,
                creation_status: arr,
              });
            }}
          />
        </div>
      </div>

      <div
        style={{
          margin: "1.5rem 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <div style={{ width: "100%", margin: "0 1rem 0 0" }}>
          <p>Is UAE:</p>
          <Select
            options={uAEOptions}
            //  defaultValue={filter.is_uae? uAEOptions.find(s=>s.value==filter.is_uae) : undefined}
            value={
              filter.is_uae
                ? uAEOptions.find((s) => s.value == filter.is_uae)
                : undefined
            }
            onChange={(e) => {
              const val = e.valueOf();
              //setUAE(val["value"]);
              setfilter({
                ...filter,
                is_uae: val["value"],
              });
            }}
          />
        </div>
        <div style={{ width: "100%", margin: "0 0 0 1rem" }}>
          <p>Provider:</p>
          <Select
            options={providerOptions}
            //  defaultValue={filter.provider? providerOptions.find(s=>s.value==filter.provider) : undefined}
            value={
              filter.provider
                ? providerOptions.find((s) => s.value == filter.provider)
                : undefined
            }
            onChange={(e) => {
              const val = e.valueOf();
              //setProvider(val["value"]);
              setfilter({
                ...filter,
                provider: val["value"],
              });
            }}
          />
        </div>
        <div style={{ width: "100%", margin: "0 0 0 1rem" }}>
          <p>Account Created:</p>
          <Input
            type={"text"}
            name="accountCreated"
            value={filter.accountCreated ?? ""}
            onChange={(e) => {
              //setAccountCreated(e.target.value);
              setfilter({
                ...filter,
                accountCreated: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div
        style={{
          margin: "1.5rem 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}>
        <div style={{ width: "100%" }}>
          <p>Email:</p>
          <Input
            type={"email"}
            name="email"
            value={filter.email ?? ""}
            onChange={(e) => {
              //setEmail(e.target.value);
              setfilter({
                ...filter,
                email: e.target.value,
              });
            }}
          />
        </div>
        <div style={{ width: "100%", margin: "0 0 0 1rem" }}>
          <p>User Id:</p>
          <Input
            name="userId"
            type={"text"}
            value={filter.userId ?? ""}
            onChange={(e) => {
              //setUserId(e.target.value);
              setfilter({
                ...filter,
                userId: e.target.value,
              });
            }}
          />
        </div>
        <div style={{ width: "100%", margin: "0 0 0 1rem" }}>
          <p>Account Id:</p>
          <Input
            type={"text"}
            name="accountId"
            value={filter.accountId ?? ""}
            onChange={(e) => {
              //setAccountId(e.target.value);
              setfilter({
                ...filter,
                accountId: e.target.value,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

const FundedExportForm = ({ isOpenExport, onCloseExport }) => {
  const [options, setOptions] = useState({
    is_signin: false,
    is_kyc_approved: false,
    is_invoice_allowd: false,
  });

  const toast = useToast();

  const handleExport = async (options) => {
    // post reason to endpoint

    const res = await setExportOptions(options);
    if (res.success) {
      const csvContent = arrayToCSV(res.data);

      // Convert the CSV string to a Blob
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      // Create a URL for the Blob
      const fileURL = URL.createObjectURL(blob);

      // Create a temporary link to trigger the download
      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute("download", "export-tttapps.csv"); // Set the desired file name
      document.body.appendChild(link);
      link.click();

      // Clean up
      URL.revokeObjectURL(link.href);
      link.parentNode.removeChild(link);

      toast({
        position: "top",
        duration: 5 * 1000,
        status: "success",
        render: () => (
          <Box
            bgColor="green.400"
            borderRadius="5px"
            color="#f5f5f5"
            p="20px"
            textAlign="center">
            <Heading fontSize="sm" textTransform="uppercase">
              {res.success}
            </Heading>
          </Box>
        ),
      });
    } else {
      toast({
        position: "top",
        duration: 5000,
        status: "error",
        render: () => (
          <Box
            bgColor="red.400"
            borderRadius="5px"
            color="#f5f5f5"
            p="20px"
            textAlign="center">
            <Heading fontSize="sm" textTransform="uppercase">
              {res.error}
            </Heading>
          </Box>
        ),
      });
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpenExport}
        scrollBehavior={"inside"}
        size={{
          sm: "md",
          lg: "md",
        }}
        onClose={() => {
          onCloseExport();
        }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="gray.500">Export Funded Users List</ModalHeader>
          <Divider style={{ borderBottom: "1.5px solid black" }} />
          <ModalCloseButton />
          <ModalBody h="100vh">
            {/* 3 checkboxes for options state */}
            <div
              style={{
                margin: "1.5rem 0",
                alignItems: "center",
                justifyContent: "space-between",
              }}>
              <label style={{ display: "flex", width: "100%" }}>
                <Checkbox
                  isChecked={options.is_signin}
                  marginRight={"10px"}
                  onChange={(e) => {
                    setOptions((prev) => ({
                      ...prev,
                      is_signin: e.target.checked,
                    }));
                  }}
                />
                <p style={{ cursor: "pointer" }}>Is Signin</p>
              </label>
              <label style={{ display: "flex", width: "100%" }}>
                <Checkbox
                  isChecked={options.is_kyc_approved}
                  marginRight={"10px"}
                  onChange={(e) => {
                    setOptions((prev) => ({
                      ...prev,
                      is_kyc_approved: e.target.checked,
                    }));
                  }}
                />
                <p style={{ cursor: "pointer" }}>Is KYC Approved</p>
              </label>
              <label style={{ display: "flex", width: "100%" }}>
                <Checkbox
                  isChecked={options.is_invoice_allowd}
                  marginRight={"10px"}
                  onChange={(e) => {
                    setOptions((prev) => ({
                      ...prev,
                      is_invoice_allowd: e.target.checked,
                    }));
                  }}
                />
                <p style={{ cursor: "pointer" }}>Is Invoice Allowed</p>
              </label>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                handleExport(options);
                onCloseExport();
              }}>
              Apply
            </Button>

            <Button
              onClick={() => {
                setOptions((prev) => ({
                  is_signin: false,
                  is_kyc_approved: false,
                  is_invoice_allowd: false,
                }));
              }}>
              Reset
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
const FundedReasonForm = ({
  isOpenReason,
  onCloseReason,
  target,
  type,
  triggerCreation,
}) => {
  const [reason, setReason] = useState("");
  const [errr, seterrr] = useState(null);
  const { refetchFundedlists } = useAllFundedlists();

  const toast = useToast();
  // edit-kyc-reason / trigger-creation / mark-creation
  const handletriggerCreation = async (reason) => {
    await triggerCreation(target, reason);
    setReason("");
  };
  const handleeditKYCDecline = async (reason) => {
    // post reason to endpoint

    const res = await setKycReason({ accountId: target?.accountId, reason });
    if (res.success) {
      //setAccId("");
      toast({
        position: "top",
        duration: 5 * 1000,
        status: "success",
        render: () => (
          <Box
            bgColor="green.400"
            borderRadius="5px"
            color="#f5f5f5"
            p="20px"
            textAlign="center">
            <Heading fontSize="sm" textTransform="uppercase">
              {res.success}
            </Heading>
          </Box>
        ),
      });
      //setFilterFundedlist({
      //	page: filterFundedlist.page,
      //	limit: filterFundedlist.limit,
      //});
      refetchFundedlists();
    } else {
      //setAccId("");
      toast({
        position: "top",
        duration: 5000,
        status: "error",
        render: () => (
          <Box
            bgColor="red.400"
            borderRadius="5px"
            color="#f5f5f5"
            p="20px"
            textAlign="center">
            <Heading fontSize="sm" textTransform="uppercase">
              {res.error}
            </Heading>
          </Box>
        ),
      });
      //setFilterFundedlist({
      //	page: filterFundedlist.page,
      //	limit: filterFundedlist.limit,
      //});
      refetchFundedlists();
    }
  };
  const handleMarkCreation = async (reason) => {
    // post reason to endpoint

    const res = await setMarkCreation({
      accountId: target?.accountId,
      paccountId: reason,
    });
    if (res.success) {
      //setAccId("");
      toast({
        position: "top",
        duration: 5 * 1000,
        status: "success",
        render: () => (
          <Box
            bgColor="green.400"
            borderRadius="5px"
            color="#f5f5f5"
            p="20px"
            textAlign="center">
            <Heading fontSize="sm" textTransform="uppercase">
              {res.success}
            </Heading>
          </Box>
        ),
      });
      //setFilterFundedlist({
      //	page: filterFundedlist.page,
      //	limit: filterFundedlist.limit,
      //});
      refetchFundedlists();
    } else {
      //setAccId("");
      toast({
        position: "top",
        duration: 5000,
        status: "error",
        render: () => (
          <Box
            bgColor="red.400"
            borderRadius="5px"
            color="#f5f5f5"
            p="20px"
            textAlign="center">
            <Heading fontSize="sm" textTransform="uppercase">
              {res.error}
            </Heading>
          </Box>
        ),
      });
      //setFilterFundedlist({
      //	page: filterFundedlist.page,
      //	limit: filterFundedlist.limit,
      //});
      refetchFundedlists();
    }
  };
  return (
    <>
      <Modal
        isOpen={isOpenReason}
        scrollBehavior={"inside"}
        size={{
          sm: "md",
          lg: "md",
        }}
        onClose={() => {
          onCloseReason();
        }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="gray.500">
            {type == "trigger-creation"
              ? "Input Manual PA Creation Reason"
              : type == "mark-creation"
              ? "Input Perfarmance Account"
              : "Input KYC Decline Reason"}
          </ModalHeader>
          <Divider style={{ borderBottom: "1.5px solid black" }} />
          <ModalCloseButton />
          <ModalBody h="100vh">
            <div>
              <Input
                type="text"
                placeholder={`Enter reason for ${
                  type == "trigger-creation"
                    ? "manual PA creation"
                    : type == "mark-creation"
                    ? "Perfarmance account Id"
                    : "KYC decline"
                }`}
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value);
                  if (errr) seterrr(null);
                }}
              />
              {!!errr && <p style={{ color: "red" }}>{errr}</p>}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => {
                if (!reason) {
                  seterrr("Reason is required");
                  return;
                }
                if (type == "trigger-creation") handletriggerCreation(reason);
                else if (type == "mark-creation") handleMarkCreation(reason);
                else handleeditKYCDecline(reason);
                onCloseReason();
              }}>
              Apply
            </Button>

            <Button
              onClick={() => {
                setReason("");
                seterrr(null);
              }}>
              Reset
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
const FundedList = () => {
  const [isFilter, setIsFilter] = useState(false);

  //  const [page, setPage] = useState(1);
  //  const [limit, setLimit] = useState(10);

  let sessionContext = Session.useSessionContext();

  //  const [paid, setPaid] = useState("");
  //  const [isSignIn, setIsSignIn] = useState("");
  //  const [email, setEmail] = useState("");
  //  const [accountId, setAccountId] = useState("");
  //  const [userId, setUserId] = useState("");
  //  const [provider, setProvider] = useState("");
  //  const [accountType, setAccountType] = useState("");
  //  const [status, setStatus] = useState("");
  //  const [kycStatus, setKycStatus] = useState("");
  //  const [accountCreated, setAccountCreated] = useState("");
  //  const [uae, setUAE] = useState("");
  //  const [invoiceStatus, setInvoiceStatus] = useState("");

  const [invoiceId, setInvoiceId] = useState([]);
  const [accId, setAccId] = useState([]);
  const [formtype, setformtype] = useState("trigger-creation");

  const [targetFunded, setTargetFunded] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenExport,
    onOpen: onOpenExport,
    onClose: onCloseExport,
  } = useDisclosure();
  const {
    isOpen: isOpenReason,
    onOpen: onOpenReason,
    onClose: onCloseReason,
  } = useDisclosure();

  //  const query = useRecoilValue(filterFundedlistSelector);

  let toast = useToast();
  document.title = "TTTSubscriptions | Funded List";

  const [filterFundedlist, setFilterFundedlist] = useRecoilState(
    filterFundedlistState
  );

  //  useEffect(() => {
  //	console.log(filterFundedlist);

  //	  }, [filterFundedlist])

  const { funded, refetchFundedlists } = useAllFundedlists(
    filterFundedlist
    //`page=${page}&limit=${limit}` + query
  );

  //  useEffect(() => {
  //    return () => {
  //      setFilterFundedlist({
  //        //payed: "",
  //        //kyc_status: "",
  //        //accountCreated: "",
  //        //creation_status: "",
  //        //is_signin: "",
  //        //invoice_status: "",
  //        //accountId: "",
  //        //userId: "",
  //        //email: "",
  //        //is_uae: "",
  //      });
  //      refetchFundedlists();
  //    };
  //  }, [durTrigger, durUpdate]);

  const [filter, setfilter] = useState(filterFundedlist);

  const handleTriggerCreation = async (fund, reason) => {
    setAccId((prev) => [...prev, fund?.accountId]);
    toast({
      position: "top",
      duration: 1000,
      status: "loading",
      render: () => (
        <Box
          bgColor="blue.400"
          borderRadius="5px"
          color="#f5f5f5"
          p="15px"
          textAlign="center">
          <Spinner />
        </Box>
      ),
    });
    const res = await getTriggerCreation({
      accountId: fund?.accountId,
      reason,
    });
    //setLimit(2);
    //setLimit(9);

    //setFilterFundedlist({
    //  limit,
    //  page,
    //});
    //setTimeout(() => setLimit(10), 1500);

    setAccId((prev) => prev.filter((e) => e != fund?.accountId));
    // refetchFundedlists();

    // setDesibaled(false);
    if (res.success) {
      //setAccId("");
      toast({
        position: "top",
        duration: 5 * 1000,
        status: "success",
        render: () => (
          <Box
            bgColor="green.400"
            borderRadius="5px"
            color="#f5f5f5"
            p="20px"
            textAlign="center">
            <Heading fontSize="sm" textTransform="uppercase">
              {res.success}
            </Heading>
          </Box>
        ),
      });
      //setFilterFundedlist({
      //	page: filterFundedlist.page,
      //	limit: filterFundedlist.limit,
      //});
      refetchFundedlists();
    } else {
      //setAccId("");
      toast({
        position: "top",
        duration: 5000,
        status: "error",
        render: () => (
          <Box
            bgColor="red.400"
            borderRadius="5px"
            color="#f5f5f5"
            p="20px"
            textAlign="center">
            <Heading fontSize="sm" textTransform="uppercase">
              {res.error}
            </Heading>
          </Box>
        ),
      });
      //setFilterFundedlist({
      //	page: filterFundedlist.page,
      //	limit: filterFundedlist.limit,
      //});
      refetchFundedlists();
    }
  };

  const handleUpdateInvoice = async (fund) => {
    setInvoiceId((prev) => [...prev, fund?.invoice_id]);
    toast({
      position: "top",
      duration: 1000,
      status: "loading",
      render: () => (
        <Box
          bgColor="blue.400"
          borderRadius="5px"
          color="#f5f5f5"
          p="15px"
          textAlign="center">
          <Spinner />
        </Box>
      ),
    });
    const res = await getUpdateInvoice(fund?.invoice_id);
    setInvoiceId((prev) => prev.filter((e) => e != fund?.invoice_id));
    if (res.success) {
      toast({
        position: "top",
        duration: 5 * 1000,
        status: "success",
        render: () => (
          <Box
            bgColor="green.400"
            borderRadius="5px"
            color="#f5f5f5"
            p="20px"
            textAlign="center">
            <Heading fontSize="sm" textTransform="uppercase">
              {res.success}
            </Heading>
          </Box>
        ),
      });

      //setLimit(9);

      //setFilterFundedlist({
      //	...filterFundedlist,
      //  limit:9,
      //  page,
      //});
      //setTimeout(() => {
      //	//setLimit(10)
      //    setFilterFundedlist({
      //		...filterFundedlist,
      //	  limit:10,
      //	});
      //}, 1500);

      refetchFundedlists();
    } else {
      toast({
        position: "top",
        duration: 5 * 1000,
        status: "error",
        render: () => (
          <Box
            bgColor="red.400"
            borderRadius="5px"
            color="#f5f5f5"
            p="20px"
            textAlign="center">
            <Heading fontSize="sm" textTransform="uppercase">
              {res.error}
            </Heading>
          </Box>
        ),
      });

      //setFilterFundedlist({
      //  payed: "",
      //  kyc_status: "",
      //  accountCreated: "",
      //  creation_status: "",
      //  is_signin: "",
      //  invoice_status: "",
      //  accountId: "",
      //  userId: "",
      //  email: "",
      //  is_uae: "",
      //  page: 1,
      //});
      refetchFundedlists();
    }
  };

  return (
    <>
      <Flex flexDir={"column"} justify={"space-evenly"}>
        <Box
          px="100px"
          py="40px"
          h="calc(100vh - 200px)"
          color="gray.50"
          overflow="auto">
          <Flex align="end" justify="end" pb="40px">
            <Button
              padding={"10px 30px"}
              mx="20px"
              rightIcon={<AiOutlineFilter size="20px" />}
              borderRadius={50}
              fontSize="12px"
              // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              boxShadow="lg"
              color="#ffff"
              // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
              className="app_btn"
              onClick={() => {
                setIsFilter(true);
                onOpen();
              }}>
              Filter
            </Button>
            <Button
              rightIcon={<AiOutlineEye size="20px" />}
              borderRadius={50}
              fontSize="12px"
              // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              boxShadow="lg"
              color="#ffff"
              className="app_btn"
              // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
              onClick={() => {
                setFilterFundedlist({
                  page: filterFundedlist.page,
                  limit: filterFundedlist.limit,
                });
              }}>
              View all
            </Button>
            <Button
              rightIcon={<AttachmentIcon size="20px" />}
              borderRadius={50}
              mx="20px"
              fontSize="12px"
              // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              boxShadow="lg"
              color="#ffff"
              // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
              className="app_btn"
              onClick={() => {
                onOpenExport();
              }}>
              Export
            </Button>
          </Flex>

          <Box
            borderRadius={15}
            bgColor="gray.50"
            color="#000"
            overflow={`scroll`}
            maxH="550px">
            {funded?.data?.length > 0 ? (
              <Table borderRadius={20} variant="striped">
                <Thead
                  fontSize="15px"
                  h="40px"
                  // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                  className="table__head">
                  <Tr
                    textAlign="center"
                    className="table__head"
                    textTransform="capitalize"
                    h="30px"
                    borderBottomWidth={1}
                    borderRadius="20px">
                    <Th color="#fff" fontSize={12}>
                      Account Id
                    </Th>
                    <Th color="#fff" fontSize={12}>
                      User Id
                    </Th>
                    <Th color="#fff" fontSize={12}>
                      Payed
                    </Th>
                    <Th color="#fff" fontSize={12}>
                      Perfarmance Account
                    </Th>
                    <Th color="#fff" fontSize={12}>
                      Status
                    </Th>
                    {/* <Th color='#fff' fontSize=215}>User_Id</Th> */}
                    <Th color="#fff" fontSize={12}>
                      login
                    </Th>
                    <Th color="#fff" fontSize={12}>
                      Email
                    </Th>
                    <Th color="#fff" fontSize={12}>
                      Signed
                    </Th>
                    {/* <Th color='#fff' fontSize=215}>KYC key</Th> */}
                    <Th color="#fff" fontSize={12}>
                      KYC
                    </Th>
                    <Th color="#fff" fontSize={12}>
                      Rithmic_Id
                    </Th>
                    <Th color="#fff" fontSize={12}>
                      CQG_Id
                    </Th>
                    <Th color="#fff" fontSize={12}>
                      Provider
                    </Th>
                    {/* <Th color='#fff' fontSize=215}>public Id</Th> */}
                    {/* <Th color='#fff' fontSize=215}>invoice Id</Th> */}
                    <Th color="#fff" fontSize={12}>
                      Invoice Status
                    </Th>
                    {/* <Th color='#fff' fontSize=215}>invoice item id</Th> */}
                    {/* <Th color='#fff' fontSize=215}>item title</Th> */}
                    <Th color="#fff" fontSize={12}>
                      Action
                    </Th>
                  </Tr>
                </Thead>

                <Tbody
                  // bgGradient={`linear(to-l, gray.100, gray.300)`}
                  color="black">
                  {funded?.data?.map((fund, i) => (
                    <Tr
                      textAlign="center"
                      fontSize={{
                        "2xl": "13px",
                        xl: "13px",
                        md: "12px",
                        lg: "12px",
                        sm: "10px",
                      }}
                      key={i}
                      // borderBottom="1px"
                      // borderColor={colors.lightBlue}
                    >
                      <Td>{fund?.accountId} </Td>
                      <Td>{fund?.userId} </Td>
                      <Td>
                        {fund?.payed === "1" ? (
                          <Badge variant="outline" colorScheme="green">
                            {getPayedStatus(fund?.payed)}
                          </Badge>
                        ) : (
                          <Badge variant="outline" colorScheme="red">
                            {getPayedStatus(fund?.payed)}
                          </Badge>
                        )}
                      </Td>
                      <Td>
                        {fund?.creation_status === "success" ? (
                          <>
                            {fund?.accountCreated || "-"}

                            {fund?.active_accounts.indexOf(
                              fund?.accountCreated
                            ) != -1 && (
                              <Badge mx={1}>
                                {`${
                                  fund?.active_accounts.indexOf(
                                    fund?.accountCreated
                                  ) + 1
                                }/3`}
                              </Badge>
                            )}
                            {fund?.active_accounts.indexOf(
                              fund?.accountCreated
                            ) == -1 && <Badge mx={1}>{`---`}</Badge>}
                          </>
                        ) : (
                          "-"
                        )}
                      </Td>
                      <Td>
                        <CreationStatus fn={fund} />
                      </Td>
                      <Td>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={`${process.env.REACT_APP_TTT_AMEMBER_URL}${fund?.user_id}#invoice-${fund?.invoice_id}`}>
                          {fund?.login}
                        </a>
                      </Td>
                      <Td>{fund?.email}</Td>
                      <Td>
                        {fund?.is_signin === "1" ? (
                          <Badge variant="outline" colorScheme="green">
                            {sessionContext &&
                            sessionContext.accessTokenPayload[
                              "st-role"
                            ].v.includes("MASTER") ? (
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href={`${process.env.REACT_APP_TTT_SIGNNOW_URL}/${fund?.document_id}`}>
                                {getSignInStatus(fund?.is_signin)}
                              </a>
                            ) : (
                              <>{getSignInStatus(fund?.is_signin)}</>
                            )}
                          </Badge>
                        ) : (
                          <Badge variant="outline" colorScheme="red">
                            {getSignInStatus(fund?.is_signin)}
                          </Badge>
                        )}
                      </Td>

                      <Td>
                        {!!fund?.kyc_status ? (
                          <Badge
                            variant="outline"
                            colorScheme={(() => {
                              switch (fund?.kyc_status) {
                                case "approved":
                                  return "green";
                                case "completed":
                                  return "gray";
                                case "created":
                                  return "gray";
                                case "declined":
                                  return "red";
                                case "failed":
                                  return "red";
                                case "needs_review":
                                  return "orange";
                                default:
                                  return "gray";
                              }
                            })()}>
                            {!!fund?.kyc_key && (
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href={`${process.env.REACT_APP_TTT_INQUIREIES_URL}/${fund?.kyc_key}`}>
                                {fund?.kyc_status}
                              </a>
                            )}
                            {!fund?.kyc_key && <span>{fund?.kyc_status}</span>}
                          </Badge>
                        ) : (
                          "-"
                        )}
                      </Td>
                      <Td>{fund?.rithmic_id}</Td>
                      <Td>{fund?.cqg_id || "-"}</Td>
                      <Td>{fund?.provider || "-"}</Td>

                      <Td>
                        <Badge
                          variant="outline"
                          colorScheme={`${
                            fund?.invoice_status === "3" ||
                            fund?.invoice_status === "4"
                              ? "red"
                              : "green"
                          }`}>
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href={`${process.env.REACT_APP_TTT_AMEMBER_URL}${fund?.user_id}#invoice-${fund?.invoice_id}`}>
                            {getInvoiceStatus(fund?.invoice_status)}
                          </a>
                        </Badge>
                      </Td>

                      <Td display={"flex"} flexDirection={"row-reverse"}>
                        <Menu isLazy>
                          <MenuButton
                            as={IconButton}
                            aria-label="Options"
                            icon={<HamburgerIcon />}
                            variant="outline"
                          />
                          <MenuList>
                            {(sessionContext?.accessTokenPayload[
                              "st-role"
                            ].v.includes("MASTER") ||
                              sessionContext?.accessTokenPayload[
                                "st-role"
                              ].v.includes("FUNDEDMASTER")) &&
                              !["3", "4"].includes(fund.invoice_status) &&
                              fund.creation_status != "success" && (
                                <MenuItem
                                  icon={<AddIcon />}
                                  isDisabled={accId.includes(fund?.accountId)}
                                  onClick={async () => {
                                    setformtype("trigger-creation");
                                    setTargetFunded(fund);
                                    onOpenReason();
                                  }}>
                                  Create PA
                                </MenuItem>
                              )}
                            <MenuItem
                              icon={<ExternalLinkIcon />}
                              onClick={() => {
                                setTargetFunded(fund);
                                onOpen();
                              }}>
                              Details
                            </MenuItem>
                            {(sessionContext?.accessTokenPayload[
                              "st-role"
                            ].v.includes("MASTER") ||
                              sessionContext?.accessTokenPayload[
                                "st-role"
                              ].v.includes("FUNDEDMASTER")) &&
                              fund.z != 1 && (
                                <MenuItem
                                  icon={<EditIcon />}
                                  onClick={async () => {
                                    let res = await setMarkSigned({
                                      user_id: +fund?.user_id,
                                    });
                                    if (res.success) {
                                      refetchFundedlists();
                                      toast({
                                        position: "top",
                                        duration: 5 * 1000,
                                        status: "success",
                                        render: () => (
                                          <Box
                                            bgColor="green.400"
                                            borderRadius="5px"
                                            color="#f5f5f5"
                                            p="20px"
                                            textAlign="center">
                                            <Heading
                                              fontSize="sm"
                                              textTransform="uppercase">
                                              {res.success}
                                            </Heading>
                                          </Box>
                                        ),
                                      });
                                    } else {
                                      toast({
                                        position: "top",
                                        duration: 5 * 1000,
                                        status: "error",
                                        render: () => (
                                          <Box
                                            bgColor="red.400"
                                            borderRadius="5px"
                                            color="#f5f5f5"
                                            p="20px"
                                            textAlign="center">
                                            <Heading
                                              fontSize="sm"
                                              textTransform="uppercase">
                                              {res.error}
                                            </Heading>
                                          </Box>
                                        ),
                                      });
                                    }
                                  }}>
                                  Mark User as Signed
                                </MenuItem>
                              )}
                            {(sessionContext?.accessTokenPayload[
                              "st-role"
                            ].v.includes("MASTER") ||
                              sessionContext?.accessTokenPayload[
                                "st-role"
                              ].v.includes("FUNDEDMASTER")) &&
                              fund.kyc_status != "approved" && (
                                <MenuItem
                                  icon={<EditIcon />}
                                  onClick={() => {
                                    setformtype("edit-kyc-reason");
                                    setTargetFunded(fund);
                                    onOpenReason();
                                  }}>
                                  Edit KYC Reason
                                </MenuItem>
                              )}
                            {(sessionContext?.accessTokenPayload[
                              "st-role"
                            ].v.includes("MASTER") ||
                              sessionContext?.accessTokenPayload[
                                "st-role"
                              ].v.includes("FUNDEDMASTER")) &&
                              fund.creation_status != "success" && (
                                <MenuItem
                                  icon={<CheckIcon />}
                                  onClick={() => {
                                    setformtype("mark-creation");
                                    setTargetFunded(fund);
                                    onOpenReason();
                                  }}>
                                  Mark as PA Created
                                </MenuItem>
                              )}
                            {(sessionContext?.accessTokenPayload[
                              "st-role"
                            ].v.includes("MASTER") ||
                              sessionContext?.accessTokenPayload[
                                "st-role"
                              ].v.includes("FUNDEDMASTER")) &&
                              ["3", "4"].includes(fund.invoice_status) &&
                              fund.creation_status != "success" && (
                                <MenuItem
                                  icon={<CheckIcon />}
                                  isDisabled={invoiceId.includes(
                                    fund?.invoice_id
                                  )}
                                  onClick={() => handleUpdateInvoice(fund)}>
                                  Allow Invoice
                                </MenuItem>
                              )}
                          </MenuList>
                        </Menu>

                        {/*<Button
													title="Funded details"
													onClick={() => {
														setTargetFunded(
															funded?.data?.filter(
																(target) =>
																	target?.accountId === fund?.accountId
															)[0]
														);

														onOpen();
													}}
													// disabled={!roles?.includes("MASTER", "COUPON")}
													bgColor="transparent"
													color="green.600"
													align="center"
													px={0}
													_hover={{ cursor: "pointer", color: "green.500" }}
												>
													<BiDetail title="Funded details" size="20px" />
												</Button>*/}
                        {/*{renderBtns(fund,durTrigger,isDisabledTrigger,accId)}*/}
                        {/*{((sessionContext?.accessTokenPayload["st-role"].v.includes("MASTER") || sessionContext?.accessTokenPayload["st-role"].v.includes("FUNDEDMASTER")) && ['1', '2', '5'].includes(fund.invoice_status) && fund.creation_status != 'success') &&
													(<Button
														disabled={accId.includes(fund?.accountId)}
														title="Trigger Creation"
														onClick={() => handleTriggerCreation(fund)}
														bgColor="transparent"
														mx={0}
														px={0}
														color="green.600"
														align="center"
														_hover={{
															cursor: "pointer",
															color: "green.500",
														}}>
														<BiPlus size="20px" />
													</Button>)
												}*/}
                        {/*{((sessionContext?.accessTokenPayload["st-role"].v.includes("MASTER") || sessionContext?.accessTokenPayload["st-role"].v.includes("FUNDEDMASTER")) && ['3', '4'].includes(fund.invoice_status) && fund.creation_status != 'success') &&
													(<Button
														disabled={invoiceId.includes(fund?.invoice_id)}
														title="Update Invoice"
														onClick={() => handleUpdateInvoice(fund)}
														px={0}
														//   disabled={!roles?.includes("MASTER", "COUPON")}
														bgColor="transparent"
														color="green.600"
														align="center"
														_hover={{
															cursor: "pointer",
															color: "green.500",
														}}
													>
														<BiEdit size="20px" />
													</Button>)
												}*/}
                        {/*<Button
													title="Edit KYC Decline Reason "
													onClick={() => {
														onOpenReason();
													}}
													px={0}
													//   disabled={!roles?.includes("MASTER", "COUPON")}
													bgColor="transparent"
													color="green.600"
													align="center"
													_hover={{
														cursor: "pointer",
														color: "green.500",
													}}
												>
													<BiEdit size="20px" />
												</Button>*/}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            ) : (
              <Flex
                borderRadius={15}
                bgGradient={`linear(to-l, gray.100, gray.300)`}
                color={colors.ocean}
                boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
                p="20px"
                h="500px"
                justify={`center`}
                align="center"
                fontSize={30}>
                No data to display !!
              </Flex>
            )}
          </Box>
        </Box>

        <Flex ml="7%">
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={<BiFilter size="20px" />}
              borderRadius={50}
              fontSize="12px"
              // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              boxShadow="lg"
              color="#ffff"
              className="app_btn"
              // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
            >
              Per Page: {filterFundedlist.limit}
            </MenuButton>
            <MenuList color={colors.ocean} fontSize="14px">
              <MenuItem
                onClick={() => {
                  //setLimit(10)
                  setFilterFundedlist({
                    ...filterFundedlist,
                    limit: 10,
                  });
                }}>
                10
              </MenuItem>
              <MenuItem
                onClick={() => {
                  //setLimit(15)
                  setFilterFundedlist({
                    ...filterFundedlist,
                    limit: 15,
                  });
                }}>
                15
              </MenuItem>
              <MenuItem
                onClick={() => {
                  //setLimit(20)
                  setFilterFundedlist({
                    ...filterFundedlist,
                    limit: 20,
                  });
                }}>
                20
              </MenuItem>
              <MenuItem
                onClick={() => {
                  //setLimit(25)
                  setFilterFundedlist({
                    ...filterFundedlist,
                    limit: 25,
                  });
                }}>
                25
              </MenuItem>
              <MenuItem
                onClick={() => {
                  //setLimit(30)
                  setFilterFundedlist({
                    ...filterFundedlist,
                    limit: 30,
                  });
                }}>
                30
              </MenuItem>
              <MenuItem
                onClick={() => {
                  //setLimit(35)
                  setFilterFundedlist({
                    ...filterFundedlist,
                    limit: 35,
                  });
                }}>
                35
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        {funded?.count && (
          <Pagination1
            pagesQuantity={Number(
              Math.ceil(Number(funded?.count) / filterFundedlist.limit)
            )}
            curPage={filterFundedlist.page}
            setCurPage={(val) => {
              setFilterFundedlist({
                ...filterFundedlist,
                page: val,
              });
            }}
          />
        )}
        <Modal
          isOpen={isOpen}
          scrollBehavior={"inside"}
          size={{
            sm: "md",
            lg: "6xl",
          }}
          onClose={() => {
            // setFilterPaymentlist({
            //   start_date: "",
            //   condition: "",
            //   action_type: "",
            //   result_order: "",
            //   end_date: "",
            //   first_name: "",
            //   last_name: "",
            //   email: "",
            //   cc_type: "",
            //   security_key: filterBy,
            // });

            onClose();
            setIsFilter(false);
            //setEmail("");
          }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader color="gray.500">
              {isFilter ? "Filters" : "Details"}
            </ModalHeader>
            <Divider style={{ borderBottom: "1.5px solid black" }} />
            <ModalCloseButton />
            <ModalBody h="100vh">
              <div>
                {isFilter ? (
                  <FilterFunded filter={filter} setfilter={setfilter} />
                ) : (
                  <DetailFunded funded={targetFunded} />
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              {isFilter && (
                <Button
                  className="app_btn"
                  colorScheme="blue"
                  mr={3}
                  onClick={() => {
                    setFilterFundedlist({
                      ...filter,
                      page: 1,
                      limit: filterFundedlist.limit,
                    });

                    setIsFilter(false);
                    onClose();
                  }}>
                  Apply
                </Button>
              )}

              {isFilter && (
                <Button
                  onClick={() => {
                    setfilter({});
                  }}>
                  Reset
                </Button>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
        {isOpenReason && (
          <FundedReasonForm
            isOpenReason={isOpenReason}
            onCloseReason={onCloseReason}
            type={formtype}
            triggerCreation={
              formtype == "trigger-creation" ? handleTriggerCreation : null
            }
            target={targetFunded}
          />
        )}
        {isOpenExport && (
          <FundedExportForm
            isOpenExport={isOpenExport}
            onCloseExport={onCloseExport}
          />
        )}
      </Flex>
    </>
  );
};

export default FundedList;
