import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Input,
  Button,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { IoReturnDownBackSharp } from "react-icons/io5";
import axios from "axios";

export default function UpdateEmail() {
  const [oldEmail, setOldEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const toast = useToast();

  const handleUpdateEmail = async () => {
    if (!oldEmail || !newEmail) {
      toast({
        title: "Validation Error",
        description: "Both email fields are required.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/projectX/updateEmail`,
        { oldEmail, newEmail }
      );
      toast({
        title: "Email Updated",
        description:
          response.data.message || "Email has been successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setOldEmail("");
      setNewEmail("");
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || "Failed to update email.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex flexDir="column" justify="space-evenly">
      <Box
        px="100px"
        py="40px"
        h="calc(100vh - 200px)"
        color="gray.50"
        overflow="auto">
        <Button
          bgGradient="linear(to-r, #008ab4, #46ced8)"
          _hover={{ bgGradient: "linear(to-r, #46ced8, #008ab4)" }}
          display={"flex"}
          color={"#fff"}
          justifyContent={"center"}
          alignItems={"center"}
          onClick={() => window.history.back()}
          gap={3}>
          <IoReturnDownBackSharp />
          Back
        </Button>
        {/* Header Section */}
        <Box w="20%" my={4}>
          <Heading size="lg" color="black">
            Update Email
          </Heading>
        </Box>

        <Flex
          align="center"
          justify="center"
          flexDir="column"
          bg="gray.100"
          p={10}
          borderRadius="md"
          boxShadow="md">
          {/* Old Email */}
          <Box mb={4} w="100%">
            <Input
              type="email"
              placeholder="Enter your old email"
              borderColor="#0177D9"
              color="#0177D9"
              _focus={{ borderColor: "#0177D9" }}
              bgColor="gray.50"
              borderRadius={8}
              boxShadow="lg"
              value={oldEmail}
              onChange={(e) => setOldEmail(e.target.value)}
            />
          </Box>

          {/* New Email */}
          <Box mb={4} w="100%">
            <Input
              type="email"
              placeholder="Enter your new email"
              borderColor="#0177D9"
              color="#0177D9"
              _focus={{ borderColor: "#0177D9" }}
              bgColor="gray.50"
              borderRadius={8}
              boxShadow="lg"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />
          </Box>

          {/* Update Button */}
          <Button
            onClick={handleUpdateEmail}
            isDisabled={loading}
            borderRadius={50}
            fontSize="14px"
            boxShadow="lg"
            color="#fff"
            px={8}
            py={6}
            bgGradient="linear(to-r, #008ab4, #46ced8)"
            _hover={{ bgGradient: "linear(to-r, #46ced8, #008ab4)" }}>
            {loading ? <Spinner size="sm" /> : "Update Email"}
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
}
