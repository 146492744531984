import { useState } from "react";
import {
  Button,
  useToast,
  Heading,
  Input,
  Flex,
  Box,
  Badge,
} from "@chakra-ui/react";
import axios from "axios";
import { colors } from "../../utils/colors";
import { useNavigate } from "react-router";
import { IoReturnDownBackSharp } from "react-icons/io5";
const SendCredentials = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const toast = useToast();

  const handleSendCredentials = async () => {
    if (!email) {
      toast({
        title: "Email is required",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    setError(null);
    setResponse(null);

    try {
      const result = await axios.get(
        `${
          process.env.REACT_APP_API_HOST
        }/admin/sendCredentials/${encodeURIComponent(email)}`
      );
      setResponse(result.data);
      toast({
        title: "Success",
        description: "Credentials sent successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      console.log("err = ", err);
      setError(err.response?.data || { error: "An error occurred" });
      toast({
        title: "Error",
        description:
          err.response?.data?.details || "Failed to send credentials",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const navigate = useNavigate();

  return (
    <Flex flexDir="column" justify="space-evenly">
      <Box
        px="100px"
        py="40px"
        h="calc(100vh - 200px)"
        color="gray.50"
        overflow="auto">
        <Button
          bgGradient="linear(to-r, #008ab4, #46ced8)"
          _hover={{ bgGradient: "linear(to-r, #46ced8, #008ab4)" }}
          display={"flex"}
          color={"#fff"}
          justifyContent={"center"}
          alignItems={"center"}
          onClick={() => navigate("/apps/projectX")}
          gap={3}>
          {" "}
          <IoReturnDownBackSharp />
          Back to ProjectX apps
        </Button>
        <Flex align="center" justify="space-evenly" pb="40px">
          <Flex w="30%" flexDir="column">
            <Box>
              <Heading color="black" size="md">
                Send User Credentials
              </Heading>
            </Box>
          </Flex>

          <Flex align="center" justify="center" w="70%">
            <Box w="90%">
              <Input
                type="email"
                value={email}
                placeholder="Enter user email..."
                borderColor={colors.blue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                bgColor="gray.100"
                borderRadius={20}
                boxShadow="lg"
                _placeholder={{
                  opacity: 0.7,
                  color: colors.ocean,
                  fontSize: 14,
                  fontStyle: "italic",
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Box>

            <Box w="50%" ml={2}>
              <Button
                borderRadius={50}
                fontSize="12px"
                boxShadow="lg"
                className="app_btn"
                color="#ffff"
                width="auto"
                px={6}
                isLoading={isLoading}
                isDisabled={!email}
                onClick={handleSendCredentials}>
                Send Credentials
              </Button>
            </Box>
          </Flex>
        </Flex>

        <Box
          borderRadius={15}
          bgColor="gray.50"
          color="#000"
          overflow="scroll"
          maxH="550px">
          {response && (
            <Badge
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={8}
              colorScheme="whatsapp">
              Credentials sent successfully
            </Badge>
          )}

          {error && (
            <Badge
              display="flex"
              justifyContent="center"
              alignItems="center"
              p={8}
              colorScheme="red">
              {error.details || error.error}
            </Badge>
          )}
        </Box>
      </Box>
    </Flex>
  );
};

export default SendCredentials;
