import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Heading,
  Input,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { BiFilter, BiSearch } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { IoReturnDownBackSharp } from "react-icons/io5";

import Pagination1 from "../../utils/pagination";
export default function ActivityLogTable() {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(3);

  const [fuzzy, setFuzzy] = useState("");
  const [after, setAfter] = useState("");
  const [before, setBefore] = useState("");

  const toast = useToast();
  const navigate = useNavigate();

  const pageSize = 10;

  useEffect(() => {
    const fetchLogs = async () => {
      setLoading(true);
      setError(null);
      try {
        const result = await axios.post(
          `${process.env.REACT_APP_API_HOST}/projectX/activityLogs`,
          {
            pageSize,
            pageOffset: page - 1,
            sorting: [
              {
                id: "timestamp",
                desc: true,
              },
            ],
            filters: {
              fuzzy: fuzzy || undefined,
              after: after || undefined,
              before: before || undefined,
            },
          }
        );
        setLogs(result.data.data);
        setTotalPages(Math.ceil(result.data.totalCount / pageSize));
      } catch (err) {
        setError("Failed to fetch activity logs");
      } finally {
        setLoading(false);
      }
    };

    fetchLogs();
  }, [page, fuzzy, after, before]);

  const resetFilters = () => {
    setFuzzy("");
    setAfter("");
    setBefore("");
  };

  return (
    <Flex flexDir="column" justify="space-evenly">
      <Box
        px="100px"
        py="40px"
        h="calc(100vh - 200px)"
        color="gray.50"
        overflow="auto">
        <Button
          bgGradient="linear(to-r, #008ab4, #46ced8)"
          _hover={{ bgGradient: "linear(to-r, #46ced8, #008ab4)" }}
          display={"flex"}
          color={"#fff"}
          justifyContent={"center"}
          alignItems={"center"}
          onClick={() => navigate("/apps/projectX")}
          gap={3}>
          {" "}
          <IoReturnDownBackSharp />
          Back to ProjectX apps
        </Button>
        {/* Header Section */}
        <Box w="20%" my={4}>
          <Heading size="lg" color="black">
            Activity Log
          </Heading>
        </Box>
        <Flex align="center" justify="space-between" pb="40px">
          <Flex align="center" justify="center" w="100%">
            <Box w="70%" mr="5px">
              <Input
                type="text"
                placeholder="Search by username or account name..."
                borderColor="#0177D9"
                color="#0177D9"
                _focus={{ borderColor: "#0177D9" }}
                bgColor="gray.100"
                borderRadius={20}
                boxShadow="lg"
                _placeholder={{
                  opacity: 0.7,
                  color: "#0177D9",
                  fontSize: 14,
                  fontStyle: "italic",
                }}
                value={fuzzy}
                onChange={(e) => setFuzzy(e.target.value)}
              />
            </Box>

            <Box w="20%">
              <Input
                type="datetime-local"
                borderColor="#0177D9"
                color="#0177D9"
                _focus={{ borderColor: "#0177D9" }}
                bgColor="gray.100"
                borderRadius={20}
                boxShadow="lg"
                value={after}
                onChange={(e) => setAfter(e.target.value)}
              />
            </Box>

            <Box w="20%" ml="5px">
              <Input
                type="datetime-local"
                borderColor="#0177D9"
                color="#0177D9"
                _focus={{ borderColor: "#0177D9" }}
                bgColor="gray.100"
                borderRadius={20}
                boxShadow="lg"
                value={before}
                onChange={(e) => setBefore(e.target.value)}
              />
            </Box>

            <Button
              borderRadius={50}
              fontSize="12px"
              boxShadow="lg"
              color="#ffff"
              ml="5px"
              px={6}
              bgGradient="linear(to-r, #008ab4, #46ced8)"
              _hover={{ bgGradient: "linear(to-r, #46ced8, #008ab4)" }}
              onClick={resetFilters}>
              <AiOutlineEye size="20px" />
            </Button>
          </Flex>
        </Flex>

        {/* Data Table */}
        <Box borderRadius={15} color="#000" overflow="scroll" maxH="550px">
          {loading ? (
            <Flex justify="center" align="center" h="300px">
              <Spinner size="xl" />
            </Flex>
          ) : error ? (
            <Box color="red.500" textAlign="center">
              {error}
            </Box>
          ) : logs.length > 0 ? (
            <Table borderRadius={20} variant="striped">
              <Thead h="40px">
                <Tr
                  textAlign="center"
                  textTransform="capitalize"
                  className="table__head"
                  h="30px"
                  borderBottomWidth={1}
                  borderRadius="20px">
                  <Th textAlign="center" color="#fff">
                    Timestamp
                  </Th>
                  <Th textAlign="center" color="#fff">
                    Account Name
                  </Th>
                  <Th textAlign="center" color="#fff">
                    Type
                  </Th>
                  <Th textAlign="center" color="#fff">
                    Message
                  </Th>
                  <Th textAlign="center" color="#fff">
                    Requested Ip
                  </Th>
                  <Th textAlign="center" color="#fff">
                    User Agent
                  </Th>
                  <Th textAlign="center" color="#fff">
                    User ID
                  </Th>
                  <Th textAlign="center" color="#fff">
                    Account ID
                  </Th>
                  <Th textAlign="center" color="#fff">
                    User Name
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {logs.map((log, index) => (
                  <Tr
                    textAlign="center"
                    fontSize={{
                      "2xl": "13px",
                      xl: "13px",
                      md: "12px",
                      lg: "12px",
                      sm: "10px",
                    }}
                    key={index}>
                    <Td textAlign="center">
                      {new Date(log.timestamp).toLocaleString()}
                    </Td>
                    <Td textAlign="center">
                      {log.accountInfo?.accountName || "-"}
                    </Td>
                    <Td textAlign="center">{log.type || "-"}</Td>
                    <Td textAlign="center">{log.message || "-"}</Td>
                    <Td textAlign="center">
                      {log.clientInfo?.ipAddress || "-"}
                    </Td>
                    <Td textAlign="center">
                      {log.clientInfo?.userAgent || "-"}
                    </Td>
                    <Td textAlign="center">{log.userInfo?.userId || "-"}</Td>
                    <Td textAlign="center">
                      {log.accountInfo?.accountId || "-"}
                    </Td>
                    <Td textAlign="center">{log.userInfo?.userName || "-"}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Flex justify="center" align="center" h="300px">
              No data available
            </Flex>
          )}
        </Box>

        {/* Pagination */}
        <Flex justify="space-between" mt={6}>
          <Button
            onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
            isDisabled={page === 1}
            borderRadius={20}
            bgGradient="linear(to-r, #46ced8, #008ab4)"
            color="#fff"
            _hover={{ bgGradient: "linear(to-r, #008ab4, #46ced8)" }}>
            Previous
          </Button>
          <Pagination1
            pagesQuantity={totalPages}
            curPage={page}
            setCurPage={setPage}
          />
          <Button
            onClick={() =>
              setPage((prev) => (prev < totalPages ? prev + 1 : prev))
            }
            isDisabled={page === totalPages}
            borderRadius={20}
            bgGradient="linear(to-r, #46ced8, #008ab4)"
            color="#fff"
            _hover={{ bgGradient: "linear(to-r, #008ab4, #46ced8)" }}>
            Next
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
}
